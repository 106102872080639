<template>
    <v-col class="mb-8" cols="6">
        <v-row v-if="show" align="center" dense justify="start">
            <v-col>
                <v-textarea v-model="description"
                            :label="$vuetify.lang.t('$vuetify.pages.site.rejectionReason')"
                            :loading="loading" hide-details outlined
                            rows="1"/>
            </v-col>
            <v-col cols="auto">
                <v-btn outlined :loading="loadingButton" @click="verifyField">save</v-btn>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
export default {
    name: "KurccRejectionReason",
    data() {
        return {
            description: "",
            loadingButton: false
        }
    },
    props: {
        verificationItems: {
            default: true
        },
        loading: {
            type: Boolean,
            default: true
        },
        id: {
            require: true
        },
        fieldName: {
            type: String,
            require: true
        },
        branchId: {},
    },
    watch: {
        verificationItems: {
            handler: function (verificationItems) {
                if (verificationItems[this.fieldName])
                    this.description = verificationItems[this.fieldName].description
            },
            immediate: true
        }
    },
    computed: {
        show() {
            if (this.verificationItems[this.fieldName])
                return !this.verificationItems[this.fieldName].is_verified
            else return false
        }
    },
    methods: {
        verifyField() {
            this.loadingButton = true
            this.$store.dispatch('verifyField', {
                field_name: this.fieldName,
                site_id: this.id,
                branch_id: this.branchId,
                is_verified: false,
                description: this.description
            })
            .finally(() => this.loadingButton = false)
        }
    }
}
</script>